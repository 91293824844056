// ! Hooks
import { EffectCallback, useEffect, useRef, useState } from "react";
import { useStore } from "pages/ReportDashboardV2/context/store";
import useApp from "hooks/useApp";
import { useEmbedApp, useTrans } from "@haravan/reactapp";
import useHistory from "hooks/useHistory";
import { useAutoCompleteV2 } from "components/Filter/swr/useAutocomplete";
import { useQueryString } from "hooks/useQueryString";
import { useGTM } from "hooks/useGTM";
import { useDetectPage } from "hooks/useDetectPage";
import dayjs from "components/Dayjs";

// ! Components
import {
  DashboardHeader,
  DashboardPanelFilter,
  DashboardSidebar,
  DashboardTopbar,
  DashboardWidgetList,
} from "../../tools";
import WidgetClone from "../../tools/Widget";
import BackToTop from "components/BackToTop";
import { Box, Button, Input, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import Modal from "components/Modal";
import HSelection from "components/Selection";
import SkeletonWidget from "pages/ReportDashboardV2/tools/Skeleton";
import ButtonSupport from "components/ButtonSupport";
import DynamicTabs from "components/DynamicTabs";
import HLimitLongString from "components/LimitLongString";
import ModalReportDashboard from "../Modal";

// ! Helpers
import {
  buildQueryString,
  getAdjustedComparisonDates,
  deepClone,
  groupArrayByField,
  handlerLabelInDatePicker,
  isEmpty,
  slugify,
} from "helpers/helpers";

import Model from "models";
import { GetDateShowType } from "helpers/date";
import { category_chart } from "pages/ReportDashboardV2/context/store/constants";
import { svgClone, svgDeleteRed, svgEditName } from "pages/ReportDashboardV2/tools/Header";

const HModal: any = Modal;

function Main() {
  const t = useTrans();
  const { showMessage } = useEmbedApp();
  const { push } = useHistory();
  const { state, dispatch, actions } = useStore();
  const { toogleFullScreen, isFullScreen, updateBreadcrumb, setMenuActive } = useApp();
  const [defaultSetting, setDefaultSetting] = useState({});
  const [defaultSidebar, setDefaultSidebar] = useState({}); // Danh sách sidebar bên trái
  const [widgetConstant, setWidgetConstant] = useState({});
  const [hoverActive, setHoverActive] = useState("");
  const [addActive, setAddActive] = useState(0);

  const refSidebar = useRef<any>();
  const refDashboardHeader = useRef<any>()

  const { detectClass, attributes } = useGTM({
    enabled: true,
    variables: {
      'ut-name': 'apply_config_report',
      'ut-screen': 'dashboard'
    }
  });

  const queryString: any = useQueryString();

  const {
    AnalyticWidget,
    AnalyticDashboard,
    SaveAnalyticWidget,
    AddDashboard,
    DeleteDashboard,
    CloneDashboard,
    ResetDashboard,
    ChangeNameDashboard,
    ListWidgetContanst
  } = Model();

  const [currentScreenFull, setCurrentScreenFull] = useState(false);
  const [actionId, setActionId] = useState("");

  useDetectPage(t("analytics"));
  updateBreadcrumb(t("analytics"), t("report"), "/dashboard");

  setMenuActive({
    label: "Bảng phân tích",
    destination: '/dashboard'
  });

  const { data: dataSourceName, isLoadingAutoCompleteSourceName } = useAutoCompleteV2({
    id: "100735",
    body: {
      dimension: "SourceName",
      keyword: "",
    },
    preventFetch: false,
    cacheTime: 1000 * 60 * 5
  });

  const { data: dataLocationId, isLoadingAutoCompleteLocationId } = useAutoCompleteV2({
    id: `${import.meta.env.VITE_APP_LOCATION_REPORT_ID}`,
    body: {
      dimension: "LocationId",
      keyword: "",
    },
    preventFetch: false,
    cacheTime: 1000 * 60 * 5
  });

  const {
    inConfigMode,
    templateDashboard,
    startdate,
    enddate,
    compareDateEnd,
    compareDateStart,
    optionCompare,
    dashboardList,
    defaultTemplateDashboard,
    optionDate,
    isLoadingWidgetDashboard,
    autoCompleteSelected,
    isLoadingTabWidget,
    isOpenConfigWidgetModal,
    widgetForConfig,
    isLoadingSave,
    modalType,
    addons
  } = state;

  const handleClickConfig = () => {
    dispatch(actions.fecthAnalyticWidget());

    dispatch(actions.toogleConfigMode(!inConfigMode));
    toogleFullScreen(true);

    setTimeout(() => {
      dispatch(actions.fecthAnalyticWidgetSuccess());

    }, 500)
  };

  const handleClickFullscreen = () => {
    dispatch(actions.fecthAnalyticWidget());

    toogleFullScreen(!isFullScreen);
    setCurrentScreenFull(!isFullScreen);

    setTimeout(() => {
      dispatch(actions.fecthAnalyticWidgetSuccess());

    }, 500)
  };

  const handleClickCancel = () => {
    handleClickConfig();

    fetchingWidgetSideBar();
    toogleFullScreen(currentScreenFull);
  };

  const handleClickSave = async () => {
    dispatch(actions.setLoadingSave());

    let convertObjectToArray = Object.values(templateDashboard).flat();

    convertObjectToArray = convertObjectToArray.filter((y: any) => y.isDisplay);

    const payload = {
      dashboardId: defaultTemplateDashboard.dashboardId,
      dashboardFilterDatas: [
        {
          filterName: "currentDate",
          startDate: startdate.format("YYYY-MM-DD"),
          endDate: enddate.format("YYYY-MM-DD"),
          label: optionDate,
        },
        {
          filterName: "periodDate",
          startDate: compareDateStart
            ? compareDateStart.format("YYYY-MM-DD")
            : "",
          endDate: compareDateEnd ? compareDateEnd.format("YYYY-MM-DD") : "",
          label: optionCompare,
        },
        {
          filterName: "SourceName",
          filterDatas: autoCompleteSelected["SourceName"],
        },
        {
          filterName: "LocationId",
          filterDatas: autoCompleteSelected["LocationId"],
        },
      ],
      widgets: convertObjectToArray.flat().map((value: any) => {
        return {
          ...value,
          template: {
            ...value.template,
            y: value.template.y === Infinity ? 0 : value.template.y
          }
        }
      }),
    };


    const { isError } = await SaveAnalyticWidget(payload);

    if (isError) {
      showMessage(t("Save_report_failed"), 'error');
      dispatch(actions.setLoadingSaveError());

    } else {
      dispatch(actions.setLoadingSaveSuccess());
      showMessage(
        t("Saved_the_report") + defaultTemplateDashboard.name + t("successful"),
      );

      setDefaultSetting(templateDashboard);

      handleClickConfig();
      toogleFullScreen(currentScreenFull);
    }

    fetchingWidgetSideBar();
  };

  // ! Lấy danh sách Widget theo tab cấu hình
  const fetchingWidgetSideBar = async () => {
    dispatch(actions.fecthAnalyticWidget());

    const { data: widgetContent, isError } = await AnalyticWidget(
      defaultTemplateDashboard.dashboardId,
    );

    const { data: widgetSidebar, isError: errorWidgetConstant } = await ListWidgetContanst();

    if (isError || errorWidgetConstant) {
      dispatch(actions.fecthAnalyticWidgetError());

      throw new Error("Something went wrong!");
    } else {
      const getWidgetIdDisplay = widgetContent.widgets.filter(y => y.isDisplay).map(y => y.widgetId);
      const rehiddenWidget = widgetSidebar.map((value) => {
        return {
          ...value,
          isDisplay: getWidgetIdDisplay.includes(value.widgetId)
        }
      })

      const widgetConstantFlat = groupArrayByField(rehiddenWidget, "typeName");
      setDefaultSidebar(widgetConstantFlat);
      setWidgetConstant(widgetConstantFlat);

      if (widgetContent?.dashboardFilterDatas?.length) {
        // ! Set default vào Data
        // * Ngày hiện tại
        let startDate: any = dayjs();
        let endDate: any = dayjs();
        // * Ngày so sánh
        let startCompareDate: any = dayjs();
        let endCompareDate: any = dayjs();
        // * Kho bán
        let LocationId: any = [];
        // * Kênh bán
        let SourceName: any = [];

        const getFilterData = (
          filters,
          filterName
        ) => {
          return filters.find((filter) => filter.filterName === filterName);
        }

        const getCurrentDate: any = getFilterData(widgetContent.dashboardFilterDatas, 'currentDate');
        const getPeriodDate: any = getFilterData(widgetContent.dashboardFilterDatas, 'periodDate');
        const getLocationId: any = getFilterData(widgetContent.dashboardFilterDatas, 'LocationId');
        const getSourceName: any = getFilterData(widgetContent.dashboardFilterDatas, 'SourceName');

        if (Boolean(queryString.startDate) && Boolean(queryString.endDate)) {
          startDate = dayjs(queryString.startDate);
          endDate = dayjs(queryString.endDate);
        } else {
          const currentDateCalculations: { [key: string]: () => void } = {
            "Tùy chọn": () => {
              startDate = dayjs(getCurrentDate.startDate);
              endDate = dayjs(getCurrentDate.endDate);
            },
            "Hôm nay": () => {
              startDate = dayjs().clone();
              endDate = dayjs().clone();
            },
            "Hôm qua": () => {
              startDate = dayjs().clone().subtract(1, "days");
              endDate = dayjs().clone().subtract(1, "days");
            },
            "7 ngày trước": () => {
              startDate = dayjs().clone().subtract(6, "days");
              endDate = dayjs().clone();
            },
            "30 ngày trước": () => {
              startDate = dayjs().clone().subtract(29, "days");
              endDate = dayjs().clone();
            },
            "Tháng trước": () => {
              startDate = dayjs().clone().subtract(1, "month").startOf("month");
              endDate = dayjs().clone().subtract(1, "month").endOf("month");
            },
            "Tháng này": () => {
              startDate = dayjs().clone().startOf("month");
              endDate = dayjs().clone().endOf("month");

              // if (endDate.isAfter(now)) {
              //   endDate = dayjs();
              // }
            },
            "Quý trước": () => {
              startDate = dayjs().subtract(1, "quarter").startOf("quarter");
              endDate = dayjs().subtract(1, "quarter").endOf("quarter");
            },
            "Quý này": () => {
              startDate = dayjs().startOf("quarter");
              endDate = dayjs().endOf("quarter");

              // if (endDate.isAfter(now)) {
              //   endDate = dayjs();
              // }
            },
            "Năm nay": () => {
              startDate = dayjs().startOf("year");
              endDate = dayjs().endOf("year");
            },
            "Năm trước": () => {
              startDate = dayjs().subtract(1, "year").startOf("year");
              endDate = dayjs().subtract(1, "year").endOf("year");
            },
          };

          if (getCurrentDate.label in currentDateCalculations) {
            currentDateCalculations[getCurrentDate.label]();
          } else {
            console.log("Please enter the right type date current");
          }
        }

        let { duration: diffCounts, type: dateShowType } = GetDateShowType(
          startDate,
          endDate,
        );

        const periodDateCalculations: { [key: string]: () => void } = {
          "Tùy chọn": () => {
            startCompareDate = dayjs(getPeriodDate.startDate);
            endCompareDate = dayjs(getPeriodDate.endDate);
          },
          "Không so sánh": () => {
            startCompareDate = dayjs();
            endCompareDate = dayjs();
          },
          "No Compare": () => {
            startCompareDate = dayjs();
            endCompareDate = dayjs();
          },
          "Kỳ trước": () => {
            const { start, end } = getAdjustedComparisonDates(startDate, endDate, diffCounts, dateShowType)

            startCompareDate = start;
            endCompareDate = end;
          },
          "Previous Period": () => {
            const { start, end } = getAdjustedComparisonDates(startDate, endDate, diffCounts, dateShowType);

            startCompareDate = dayjs(start);
            endCompareDate = dayjs(end);
          },
          "Năm trước": () => {
            startCompareDate = dayjs(startDate).subtract(1, "year");
            endCompareDate = dayjs(endDate).subtract(1, "year");
          },
          "Previous Year": () => {
            startCompareDate = dayjs(startDate).subtract(1, "year");
            endCompareDate = dayjs(endDate).subtract(1, "year");
          },
        };

        if (getPeriodDate.label in periodDateCalculations) {
          periodDateCalculations[getPeriodDate.label]();
        } else {
          console.log("Please enter the right type date period");
        }

        dispatch(actions.setDate({ startDate: startDate.startOf('day'), endDate: endDate.endOf("day") }));

        dispatch(actions.setLabelDate(getCurrentDate.label));

        dispatch(
          actions.setDateCompare({
            startDate: startCompareDate.startOf('day'),
            endDate: endCompareDate.endOf("day"),
          }),
        );

        dispatch(actions.setOptionCompare(getPeriodDate.label));

        if (getLocationId) {
          if (queryString.LocationId && queryString.LocationId.length) {
            LocationId = queryString.LocationId.split(",");
          } else {
            LocationId = getLocationId.filterDatas;
          }
        }

        if (getSourceName) {
          if (queryString.SourceName && queryString.SourceName.length) {
            SourceName = queryString.SourceName.split(",");
          } else {
            SourceName = getSourceName.filterDatas;
          }
        }

        dispatch(
          actions.setAutocompleteSelected({
            LocationId,
            SourceName,
          }),
        );
      }

      const mergeData = widgetSidebar?.map((defaultWidget: any) => {
        const findIndexByWidgetId = widgetContent.widgets.findIndex(
          (responseWidget) =>
            parseInt(responseWidget.widgetId) === parseInt(defaultWidget.widgetId),
        );

        if (findIndexByWidgetId !== -1) {
          return {
            ...widgetContent.widgets[findIndexByWidgetId],
            order_by: { dimension: "day", direction: "desc" },
          };
        } else {
          return {};
        }
      });

      const result = groupArrayByField(mergeData, "typeName");
      const cloneObject = deepClone(result);

      dispatch(actions.setTemplateDashboard(result));
      setDefaultSetting(cloneObject);

      dispatch(actions.fecthAnalyticWidgetSuccess());
    }
  };

  // ! Lấy danh sách tab người dùng cấu hình trên dashboard
  const fetchingDashboardTab = async () => {
    dispatch(actions.fetchingDashboardTab());

    const { data, isError } = await AnalyticDashboard();

    if (isError) {
      dispatch(actions.fetchingDashboardTabError());

      throw new Error("Something went wrong!");
    } else {
      dispatch(actions.fetchingDashboardTabSuccess());

      dispatch(actions.setDashboardList(data));

      const findDashboardActive = data.find((item) => item.orderNumber === 1);
      dispatch(actions.setDashboardDefault(findDashboardActive));
    }
  };

  const handleClickAddDashboard = async () => {
    const { isError, data } = await AddDashboard();

    if (isError) {
      showMessage(t("Adding_new_dashboard_failed"), 'error');
    } else {
      const final = [...dashboardList, data];
      dispatch(actions.setDashboardList(final));
      dispatch(actions.setDashboardDefault(data));
      showMessage(t("New_dashboard_added_successfully"));
    }

    refDashboardHeader.current.reset();
  };

  // ! Xoá dashboard Tab
  const handleRemoveDashboard = async (dashboardId) => {
    const { isError } = await DeleteDashboard(dashboardId);

    if (isError) {
      showMessage(t("Delete_dashboard_failed"), 'error');
    } else {
      const final = [...dashboardList];

      const removeItemIndex = final.findIndex(
        (y) => y.dashboardId === dashboardId,
      );
      final.splice(removeItemIndex, 1);

      if (dashboardId === defaultTemplateDashboard.dashboardId) {
        dispatch(actions.setDashboardDefault(final[removeItemIndex - 1]));
      }

      dispatch(actions.setDashboardList(final));
      showMessage(t("Successful_deletion_of_dashboard"));
    }
  };

  // ! Nhân bản dashboard tab
  const handleCloneDashboard = async (dashboardId) => {
    if (dashboardList.length >= 5) {
      return showMessage(t("limit_dashboard"), 'error');
    }
    const { isError, data } = await CloneDashboard(dashboardId);

    if (isError) {
      showMessage(t("Clone_dashboard_failed"), 'error');
    } else {
      const final = [...dashboardList, data];

      dispatch(actions.setDashboardList(final));
      dispatch(actions.setDashboardDefault(data));

      showMessage(t("Clone_dashboard_successful"));
    }

    fetchingWidgetSideBar();
  };

  // ! Đặt lại dashboard tab
  const handleResetDashboard = async (dashboardId) => {
    const { isError } = await ResetDashboard(dashboardId);

    if (isError) {
      showMessage(t("Reset_dashboard_failed"), 'error');
    } else {
      showMessage(t("Successful_dashboard_reset"));

      if (defaultTemplateDashboard.dashboardId) {
        setTimeout(() => {
          fetchingWidgetSideBar();
        }, 500);
      }
    }

    refSidebar && refSidebar.current && refSidebar.current.resetSearch()
  };

  // ! Thay đổi tên dashboard tab
  const handleChangeNameDashboard = async (dashboardId, nameDashboard) => {
    const { isError } = await ChangeNameDashboard(dashboardId, nameDashboard);

    if (isError) {
      showMessage(t("Edit_dashboard_name_failed"), 'error');
    } else {
      const final = [...dashboardList];

      const updateItemIndex = final.findIndex(
        (y) => y.dashboardId === dashboardId,
      );

      final[updateItemIndex].name = nameDashboard;

      dispatch(actions.setDashboardList(final));
      showMessage(t("Successful_editing_of_dashboard_name"));
    }
  };

  const useEffectFetchLibraryReport = (effect: EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(effect, [defaultTemplateDashboard]);
  };

  useEffectFetchLibraryReport(() => {
    if (defaultTemplateDashboard.dashboardId) {
      fetchingWidgetSideBar();
      // fetchingAutocomplete();
    }
  });

  const useEffectFetchDashboardTab = (effect: EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(effect, []);
  };

  useEffectFetchDashboardTab(() => {
    fetchingDashboardTab();
  });

  // ! Tính năng thêm || xoá Widget từ Sidebar và Content template
  const handleToggleWidget = async (typeName, widgetId, isDisplay) => {
    let result = deepClone(templateDashboard);

    if (result?.hasOwnProperty(typeName)) {
      let indexWidget = result[typeName].findIndex(
        (y) => y.widgetId === widgetId,
      );

      let indexWidgetReplace = widgetConstant[typeName].findIndex(
        (y) => y.widgetId === widgetId,
      );

      // * Tìm được vị trí của widget cần thay đổi bên trong typeName;
      result[typeName][indexWidget].isDisplay = isDisplay;
      result[typeName][indexWidget] = {
        ...widgetConstant[typeName][indexWidgetReplace],
        isDisplay
      }

      let sizeWidgetDefault = {
        w: 4,
        h: 3,
      };

      if (result[typeName][indexWidget].chartType === "number") {
        sizeWidgetDefault = {
          w: 2,
          h: 1,
        };
      }

      if (isDisplay) {
        const templateCol = 8; // chia số cột ra thành 8
        // TODO: Lưu ý viết thêm hàm làm mới lại position của widget bởi vì khi thêm vào widget luôn luôn được cho vào bên dưới cùng của Content
        const arrayReport = Object.values(result).flat();

        const getReportDisplay: any = arrayReport.filter(
          (y: any) => y.isDisplay,
        );
        const positionY = getReportDisplay.map((a) => a.template.y);

        const highestYPosition = Math.max(...positionY);

        const highestReportHaveYPosition = getReportDisplay.filter(
          (snapshot) => snapshot.template.y === highestYPosition,
        );

        let positionX = 0;

        if (getReportDisplay.length > 14) {
          return showMessage(t("warning_limit_report"), 'error');
        }

        if (getReportDisplay.length === 1) {
          positionX = 0;
        } else {
          if (highestReportHaveYPosition.length === 1) {
            // ! TH: case chỉ có 1 report
            let { x, w } = highestReportHaveYPosition[0].template;

            if (x === 0) {
              // TH: mép trái
              if (x + w === templateCol) {
                positionX = 0;
              } else {
                positionX =
                  templateCol - (x + w) >=
                    result[typeName][indexWidget].template.w
                    ? x + w
                    : 0;
              }
            } else if (x !== 0 && x + w === templateCol) {
              // TH: mép phải
              positionX = 0;
            } else {
              positionX =
                templateCol - (x + w) >=
                  result[typeName][indexWidget].template.w
                  ? x + w
                  : 0;
            }
          }
        }

        setAddActive(result[typeName][indexWidget].widgetId);

        setTimeout(() => {
          setAddActive(0)
        }, 300);

        result[typeName][indexWidget].template = {
          ...result[typeName][indexWidget].template,
          x: positionX,
          y: Infinity,
          ...sizeWidgetDefault,
        };
      } else {
        result[typeName][indexWidget].template = {
          ...defaultSetting[typeName][indexWidget].template,
          ...sizeWidgetDefault,
          x: 0,
          y: 0,
        };
      }
    } else {
      throw new Error("Please enter right Category");
    }

    dispatch(actions.setTemplateDashboard(result));

    if (!isEmpty(defaultSidebar)) {
      const findSidebarReport = deepClone(defaultSidebar);

      let indexWidget = findSidebarReport[typeName].findIndex(
        (y) => y.widgetId === widgetId,
      );

      findSidebarReport[typeName][indexWidget].isDisplay = isDisplay;

      setDefaultSidebar(findSidebarReport);
    } else {
      setDefaultSidebar(result);
    }
  };

  const handleDragStop = (newLayout) => {
    let result = { ...templateDashboard };

    // ! Loop hết toàn bộ NewLayout và Fill layout mới vào cho từng item bên trong category
    newLayout.forEach((newTemplate) => {
      for (const key in result) {
        if (Object.prototype.hasOwnProperty.call(result, key)) {
          const itemChild = result[key];

          const indexItem = itemChild.findIndex((y) => {
            return y.widgetId === newTemplate.i;
          });

          if (indexItem !== -1) {
            result[key][indexItem].template = newTemplate;
          }
        }
      }
    });

    dispatch(actions.setTemplateDashboard(result));
  };

  // ! Set ngày so sánh
  const handleChangeCompareDate = (startDate, endDate, label) => {
    dispatch(actions.setDateCompare({ startDate, endDate }));
  };

  // ! Set ngày hiện tại
  const handleChangeDate = (startDate, endDate, label) => {
    dispatch(actions.setDate({ startDate, endDate }));

    dispatch(actions.setLabelDate(handlerLabelInDatePicker(label)));

    const queryParamsPayload = {
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      SourceName: autoCompleteSelected["SourceName"].length
        ? autoCompleteSelected["SourceName"]
        : "",
      LocationId: autoCompleteSelected["LocationId"].length
        ? autoCompleteSelected["LocationId"]
        : "",
    };

    for (const key in queryParamsPayload) {
      if (!queryParamsPayload[key].length || !queryParamsPayload[key]) {
        delete queryParamsPayload[key];
      }
    }

    const queryParams = buildQueryString(queryParamsPayload);

    push(`/dashboard${queryParams}`);
  };

  // ! Thay đổi list tab
  const handleChangeDashboardList = (dashboardSelected) => {
    dispatch(actions.setDashboardDefault(dashboardSelected));
  };

  const handleSearchReport = (keyword) => {
    let getDisplayReportInTemplate = Object.values(templateDashboard).flat();
    let hardListDashboard: any = Object.values(widgetConstant).flat();

    const getWidgetIdDisplay = getDisplayReportInTemplate.filter((y: any) => y.isDisplay).map((y: any) => y.widgetId);
    const rehiddenWidget = hardListDashboard?.map((value) => {
      return {
        ...value,
        isDisplay: getWidgetIdDisplay.includes(value.widgetId)
      }
    })

    const widgetConstantFlat = groupArrayByField(rehiddenWidget, "typeName");

    if (keyword.length) {
      let getDisplayReportInTemplate = Object.values(widgetConstantFlat).flat();

      let result = getDisplayReportInTemplate?.filter((y: any) => {
        return (
          (!y.isDisplay &&
            y["measures"]
              .map((val) => {
                return val.measureField && val.measureField.length
                  ? t(val.measureField.toLocaleLowerCase())
                  : val.measureField;
              })
              .includes(
                keyword && keyword.length
                  ? slugify(keyword?.toLocaleLowerCase())
                  : "",
              )) ||
          slugify(t(y["reportName"])?.toLocaleLowerCase()).includes(
            slugify(
              keyword && keyword.length
                ? slugify(keyword?.toLocaleLowerCase())
                : "",
            ),
          ) ||
          slugify(t(y["description"])?.toLocaleLowerCase()).includes(
            slugify(
              keyword && keyword.length
                ? slugify(keyword?.toLocaleLowerCase())
                : "",
            ),
          )
        );
      });

      result = groupArrayByField(result, "typeName");
      setDefaultSidebar(result);
    } else {

      setDefaultSidebar(widgetConstantFlat);
    }

  };

  const handleChangeFilter = (fieldName, value) => {
    const payload = { ...autoCompleteSelected, [fieldName]: value };

    dispatch(actions.setAutocompleteSelected(payload));

    const queryParamsPayload = {
      startDate: startdate.format("YYYY-MM-DD"),
      endDate: enddate.format("YYYY-MM-DD"),
      SourceName: payload["SourceName"],
      LocationId: payload["LocationId"],
    };

    for (const key in queryParamsPayload) {
      if (!queryParamsPayload[key].length || !queryParamsPayload[key]) {
        delete queryParamsPayload[key];
      }
    }

    const queryParams = buildQueryString(queryParamsPayload);

    push(`/dashboard${queryParams}`);
  };

  const handleOpenOptionalConfig = (widget) => {
    dispatch(actions.setDataWidgetForConfig(widget));
    dispatch(actions.toggleConfigWidgetModal(true));
  };

  const headerContentConfig = () => {
    return (
      <div className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-between">
        <Box textStyle="h4">{t("Tuỳ chỉnh báo cáo")}</Box>

        <span
          style={{
            width: 18,
          }}
          className="hrv-report-cursor-pointer"
          onClick={() => {
            dispatch(actions.toggleConfigWidgetModal(false));
            // & set mặc định báo cáo
            dispatch(actions.setDataWidgetForConfig({}));

          }}
        >
          {svgClose}
        </span>
      </div>
    );
  };

  const bodyContentConfig = () => {
    const fieldConfig = [
      {
        name: t("reportName"),
        component: "input",
        placeholder: "Vui lòng nhập tên báo cáo",
        defaultValue: widgetForConfig.reportName,
        onChange: (e) => {
          dispatch(
            actions.setDataWidgetForConfig({
              ...widgetForConfig,
              reportName: e.target.value,
            }),
          );
        },
        limit: 100,
      },
      {
        name: t("description"),
        component: "input",
        placeholder: "Vui lòng nhập mô tả",
        defaultValue: widgetForConfig.description,
        onChange: (e) => {
          dispatch(
            actions.setDataWidgetForConfig({
              ...widgetForConfig,
              description: e.target.value,
            }),
          );
        },
        limit: 150,
      },
      {
        name: t("groupProperties"),
        component: "selection",
        list: !isEmpty(widgetForConfig)
          ? widgetForConfig?.groupProperties
            // .filter((t) => t.isDisplay)
            .map((y) => {
              return { ...y, name: y.name, value: y.fieldName };
            })
          : [],
        defaultValue: !isEmpty(widgetForConfig)
          ? widgetForConfig?.groupProperties?.find((y: any) => y.isSelected)
            ?.fieldName
          : "",
        onChange: (value) => {
          let final = [...widgetForConfig.groupProperties];
          let result = final.map((y) => {
            return { ...y, isSelected: false };
          });
          let findIndexGroupby = result.findIndex((y) => y.fieldName === value);

          result[findIndexGroupby].isSelected = true;

          dispatch(
            actions.setDataWidgetForConfig({
              ...widgetForConfig,
              groupProperties: result,
            }),
          );
        },
      },
      {
        name: t("measures"),
        component: "selection",
        list: !isEmpty(widgetForConfig)
          ? widgetForConfig?.measures.map((y) => {
            return { ...y, name: y.measureName, value: y.measureField };
          })
          : [],
        defaultValue: !isEmpty(widgetForConfig)
          ? widgetForConfig?.measures?.find((y: any) => y.isSelected)
            ?.measureField
          : "",
        onChange: (value) => {
          let final = [...widgetForConfig.measures];
          let result = final.map((y) => {
            return { ...y, isSelected: false };
          });
          let findIndexMeasure = result.findIndex(
            (y) => y.measureField === value,
          );

          result[findIndexMeasure].isSelected = true;

          dispatch(
            actions.setDataWidgetForConfig({
              ...widgetForConfig,
              measures: result,
            }),
          );
        },
      },
      {
        name: t("supportChartTypes"),
        component: "selection",
        list: widgetForConfig?.supportChartTypes?.map((y) => {
          return {
            value: y,
            name: y,
            icon: renderIcon(y)
          };
        }),
        defaultValue: widgetForConfig.chartType,
        onChange: (value) => {
          dispatch(
            actions.setDataWidgetForConfig({
              ...widgetForConfig,
              chartType: value,
            }),
          );
        },
      },
    ];

    const valid = (inputValue, limit, field) => {
      let error = "";

      if (!inputValue?.trim().length) {
        error = t("Vui lòng không để trống")
      }

      if (inputValue?.trim().length > limit) {
        error = `${t(field.name)} ${t("không được vượt quá")} ${limit} ${t("ký tự")}`;
      }

      return error;
    };

    return (
      <div className="hrv-report-d-flex hrv-report-justify-between">
        <div
          className="hrv-report-mr-16"
          style={{
            width: "60%",
          }}
        >
          <WidgetClone
            {...widgetForConfig}
            currentIdResizing={widgetForConfig.widgetId}
            reportId={widgetForConfig.reportScreenId}
            isConfigMode={inConfigMode}
            isFullScreen={isFullScreen}
            startdate={startdate}
            enddate={enddate}
            compareDateEnd={compareDateEnd}
            compareDateStart={compareDateStart}
            optionCompare={optionCompare}
            currentHeightResizing={430}
            autoCompleteSelected={autoCompleteSelected}
            autoComplete={{
              SourceName: dataSourceName?.data || [],
              LocationId: dataLocationId?.data || [],
            }}
            isInSettingScreen={true}
            hoverActive={hoverActive}
          />
        </div>


        <div
          className="hrv-report-whitebox-no-bs"
          style={{
            width: "40%",
          }}
        >
          <>
            {fieldConfig.map((optional, idx) => {
              return (
                <div
                  key={idx}
                  className={`${idx === fieldConfig.length - 1 ? "" : "hrv-report-mb-16"
                    }`}
                >
                  <p className="hrv-report-mb-4 hrv-report-fs-14 hrv-report-fw-500 hrv-report-text-color-black-6">
                    {optional.name}
                  </p>

                  {optional.component === "input" && (
                    <>
                      <Input
                        placeholder={t(optional?.placeholder || "")}
                        value={optional.defaultValue}
                        onChange={optional.onChange}
                        size="m"
                        className={`${valid(optional.defaultValue, optional.limit, optional).length
                          ? "has-error"
                          : ""
                          }`}
                        onFocus={() => {
                          setHoverActive(optional.name)
                        }}
                        onBlur={() => {
                          setHoverActive('')
                        }}
                      />

                      <p className="hrv-report-mt-4 hrv-report-mb-0 hrv-report-text-color-red-1">
                        {valid(optional.defaultValue, optional.limit, optional)}
                      </p>
                    </>
                  )}

                  {optional.component === "selection" && (
                    <HSelection
                      dropdownClassName="hrv-report-no-scroll-selection_dropdown"
                      options={optional.list}
                      name="name"
                      value="value"
                      defaultValue={optional.defaultValue}
                      onChange={optional.onChange}
                      disabled={
                        ["1000000026", "1000000020"].includes(
                          widgetForConfig.widgetId,
                        ) && optional.name === t("measures")
                      }
                      hasIcon={optional.name === t("supportChartTypes")}
                      nameUserTracking={"support_chart_types"}
                      screenUserTracking={"dashboard"}
                      enabledTracker={optional.name === t("supportChartTypes")}
                    />
                  )}
                </div>
              );
            })}
          </>
        </div>
      </div>
    );
  };

  const footerContentConfig = () => {
    return (
      <div className="hrv-report-w-full hrv-report-d-flex hrv-report-justify-between hrv-report-items-center">
        <span></span>

        <div className="hrv-report-d-flex">
          <Button
            variant="default-default"
            onClick={() => {
              dispatch(actions.setDataWidgetForConfig({}));
              dispatch(actions.toggleConfigWidgetModal(false));
            }}
            className="hrv-report-mr-8"
          >
            {t("cancelLabel")}
          </Button>

          <Button
            variant="default-primary"
            onClick={() => {
              handleApplyConfigWidget(
                widgetForConfig.typeName,
                widgetForConfig.widgetId,
              );
            }}
            className={detectClass}
            {...attributes}
          >
            {t("applyLabel")}
          </Button>
        </div>
      </div>
    );
  };

  const openModalConfirm = (id, action) => {
    setActionId(id);
    dispatch(actions.setModalType(action));

    switch (action) {
      case "delete":
        dispatch(actions.setAddons({
          ...addons,
          dashboardName: dashboardList.find((t) => t.dashboardId === id).name
        }))
        break;

      default:
        break;
    }
  };

  const handleApplyConfigWidget = async (typeName, widgetId) => {
    if (
      !widgetForConfig.reportName.trim().length ||
      widgetForConfig.reportName.trim().length > 100
    ) {
      return showMessage(t("Vui lòng kiểm tra lại"), 'error');
    }

    if (
      !widgetForConfig.description.trim().length ||
      widgetForConfig.description.trim().length > 150
    ) {
      return showMessage(t("Vui lòng kiểm tra lại"), 'error');
    }

    let result = await deepClone(templateDashboard);

    if (result?.hasOwnProperty(typeName)) {
      let indexWidget = await result[typeName].findIndex(
        (y) => y.widgetId === widgetId,
      );

      // * Tìm được vị trí của widget cần thay đổi bên trong typeName;
      result[typeName][indexWidget] = widgetForConfig;


      dispatch(actions.setTemplateDashboard(result));

      dispatch(actions.toggleConfigWidgetModal(false));

      showMessage(`${t("Lưu báo cáo")} ${widgetForConfig.reportName} ${t("successful")}`);
    }
  };

  const renderIcon = (chartName) => {
    switch (chartName) {
      case category_chart.TREEMAP:
        return svgTreemap;

      case category_chart.COLUMN:
      case category_chart.GROUPEDCOLUMN:
      case category_chart.STACKEDBAR:
        return svgVerticalColumn;

      case category_chart.LINE:
        return svgLine;

      case category_chart.HORIZONTALCOLUMN:
      case category_chart.GROUPEDHORIZONTALCOLUMN:
      case category_chart.STACKEDCOLUMN:
      case category_chart.HORIZONTALBAR:
        return svgHorizontalColumn;

      case category_chart.DOUGHNUT:
        return svgDoughNut;

      default:
        break;
    }
  };

  const handleChangeTab = (tab) => {
    if (tab.dashboardId === defaultTemplateDashboard.dashboardId) return;

    refDashboardHeader.current.checkValidation();

    // clear("/dashboard", ['endDate', 'startDate', 'SourceName', 'LocationId'])

    push("/dashboard");
    handleChangeDashboardList(tab);
  };

  const actionsList = [
    {
      action_name: "Đổi tên",
      action_icon: svgEditName,
      action_handle: () => {
        refDashboardHeader.current.toggleEditorName(true)
      },
      button_variant: "default-tertiary",
      isDisplay: true,
      className: detectClass,
      attributes: {
        "ut-screen": "dashboard",
        "ut-name": 'rename_inside_menu'
      }
    },
    {
      action_name: "Replication",
      action_icon: svgClone,
      button_variant: "default-tertiary",
      isDisplay: true,
      action_handle: (dashboardId) => {
        handleCloneDashboard(dashboardId);
      },
      className: detectClass,
      attributes: {
        "ut-screen": "dashboard",
        "ut-name": 'clone_inside_menu'
      }
    },
    {
      action_name: "Delete",
      action_icon: svgDeleteRed,
      button_variant: "critical-tertiary",
      action_handle: (dashboardId) => {
        openModalConfirm(dashboardId, "delete")
      },
      isDisplay: true,
      className: detectClass,
      attributes: {
        "ut-screen": "dashboard",
        "ut-name": 'delete_inside_menu'
      }
    },
  ];

  const handleApplyInModal = (type) => {
    switch (type) {
      case 'reset':
        handleResetDashboard && handleResetDashboard(actionId)
        break;

      case 'delete':
        handleRemoveDashboard && handleRemoveDashboard(actionId);
        break;

      default:
        break;
    }

    dispatch(actions.setModalType(""));
  }

  return (
    <>
      {inConfigMode && (
        <>
          <DashboardTopbar
            inConfigMode={inConfigMode}
            onClickCancel={handleClickCancel}
            onClickSave={handleClickSave}
            isLoadingSave={isLoadingSave}
          />

          <DashboardSidebar
            inConfigMode={inConfigMode}
            templateDashboard={defaultSidebar}
            onHandleToggleWidget={handleToggleWidget}
            onHandleSearchReport={handleSearchReport}
            ref={refSidebar}
            limit={7}
          />
        </>
      )}

      <main style={{
        overflow: "hidden"
      }}>
        {!inConfigMode && <>
          <DynamicTabs
            onHandleChangeList={handleChangeTab}
            onHandleClickAdd={handleClickAddDashboard}
            tabList={dashboardList}
          >
            {dashboardList.map((dashboard, idx) => {
              const isActiveTab = dashboard.dashboardId ===
                defaultTemplateDashboard.dashboardId;

              return (
                <li
                  key={`dynamic_${idx}_33221Ss`}
                  style={{
                    flexShrink: 0
                  }}
                  attr-active={isActiveTab ? 1 : 0}
                  className={`${isActiveTab
                    ? "hrv-menu-tab__active"
                    : "hrv-menu-tab__disable"} hrv-menu-tab ${idx === dashboardList.length - 1
                      ? ""
                      : "hrv-report-mr-8"}`}
                >
                  <div className="hrv-report-d-flex">
                    <HLimitLongString
                      text={dashboard.name}
                      lengthText={30}
                      placement="bottom"
                      customClass="hrv-report-mb-0"
                    />

                    {isActiveTab &&
                      <Menu
                        placement="bottom-end"
                        size="s"
                      >
                        <MenuButton
                          as={Box}
                          ml={2}
                        >
                          {svgDropDownIcon}
                        </MenuButton>

                        <MenuList >
                          {actionsList.map((action: any, idx) => {
                            const isHidden = ["Delete", 'Đổi tên'].includes(action.action_name) && dashboard.isSystem

                            return <>
                              {!isHidden && <MenuItem
                                as={Box}
                                key={idx}
                              >
                                <Button
                                  variant={action.button_variant}
                                  onClick={() => {
                                    action.action_handle(dashboard?.dashboardId);
                                  }}
                                  leftIcon={action.action_icon}
                                  isDisabled={action.action_name === "Delete" && dashboard.isSystem}
                                  style={{
                                    width: "100%",
                                    justifyContent: "start",
                                    fontWeight: 400,
                                  }}
                                  className={action.className}
                                  {...action.attributes}
                                >
                                  {t(action.action_name)}
                                </Button>
                              </MenuItem>}
                            </>
                          })}
                        </MenuList>
                      </Menu>
                    }
                  </div>
                </li>
              );
            })}
          </DynamicTabs>
        </>}

        <DashboardHeader
          onClickConfig={handleClickConfig}
          onClickFullscreen={handleClickFullscreen}
          isFullScreen={isFullScreen}
          isConfigMode={inConfigMode}
          dashboardList={dashboardList}
          onChangeDashboardList={handleChangeDashboardList}
          defaultTemplateDashboard={defaultTemplateDashboard}
          isLoading={isLoadingTabWidget}
          onHandleClickAddDashboard={handleClickAddDashboard}
          onHandleRemoveDashboard={handleRemoveDashboard}
          onHandleOpenModalConfirm={openModalConfirm}
          onHandleCloneDashboard={handleCloneDashboard}
          onHandleChangeNameDashboard={handleChangeNameDashboard}
          onHandleResetDashboard={handleResetDashboard}
          ref={refDashboardHeader}

        />

        <DashboardPanelFilter
          onChangeDate={handleChangeDate}
          onChangeCompareDate={handleChangeCompareDate}
          startdate={startdate}
          enddate={enddate}
          compareDateEnd={compareDateEnd}
          compareDateStart={compareDateStart}
          optionCompare={optionCompare}
          onChangeLabelCompareDate={(label) => {
            dispatch(actions.setOptionCompare(label));
          }}
          autoComplete={{
            SourceName: dataSourceName?.data || [],
            LocationId: dataLocationId?.data || [],
          }}
          autoCompleteSelected={autoCompleteSelected}
          onHandleOnChangeFilter={handleChangeFilter}
          isLoading={isLoadingWidgetDashboard || isLoadingAutoCompleteSourceName || isLoadingAutoCompleteLocationId}
          isConfigMode={inConfigMode}
        />


        {isLoadingWidgetDashboard || isLoadingAutoCompleteSourceName || isLoadingAutoCompleteLocationId ? (
          <div className="hrv-report-row hrv-report-mt-16" style={{
            marginRight: "-16px"
          }}>
            {Array(4).fill(0).map((_, id) => {
              return <div className="hrv-report-col-12 hrv-report-md-col-6 hrv-report-mb-16 hrv-report-pr-16" key={id}>
                <SkeletonWidget />
              </div>
            })}

          </div>
        ) : (
          <DashboardWidgetList
            isConfigMode={inConfigMode}
            isFullScreen={isFullScreen}
            templateDashboard={templateDashboard}
            onHandleToggleWidget={handleToggleWidget}
            onHandleDragStop={handleDragStop}
            startdate={startdate}
            enddate={enddate}
            compareDateEnd={compareDateEnd}
            compareDateStart={compareDateStart}
            optionCompare={optionCompare}
            autoCompleteSelected={autoCompleteSelected}
            autoComplete={{
              SourceName: dataSourceName?.data || [],
              LocationId: dataLocationId?.data || [],
            }}
            onHandleOpenOptionalConfig={handleOpenOptionalConfig}
            dashboardId={defaultTemplateDashboard.dashboardId}
            addActive={addActive}
          />
        )}

        <ModalReportDashboard
          type={modalType}
          onClickClose={() => {
            dispatch(actions.setModalType(""))
          }}
          onClickApply={handleApplyInModal}
        />

        <div>
          {!isEmpty(widgetForConfig) && <HModal
            renderHeader={headerContentConfig}
            renderBody={bodyContentConfig}
            renderFooter={footerContentConfig}
            onClose={() => {
              dispatch(actions.setDataWidgetForConfig({}));
              dispatch(actions.toggleConfigWidgetModal(false));
            }}
            visible={isOpenConfigWidgetModal}
            closable={false}
            size="lg"
            isCentered
            variant="config"
          ></HModal>}

        </div>


        <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-py-52">
          <ButtonSupport
            url={
              "https://support.haravan.com/support/solutions/articles/42000081037-t%E1%BB%95ng-quan-v%E1%BB%81-b%C3%A1o-c%C3%A1o#content1"
            }
            title={t("support_analytics")}
          />
        </div>
      </main >

      <BackToTop />
    </>
  );
}

export default Main;

const svgClose = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
      fill="#4B5563"
    />
  </svg>
);

const svgLine = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M16.5516 8.82728C16.3711 8.82728 16.2014 8.79138 16.0424 8.7334L12.7893 11.7592C12.8322 11.9105 12.8628 12.0671 12.8628 12.2328C12.8628 13.2122 12.056 14 11.0523 14C10.0481 14 9.24187 13.2122 9.24187 12.2328C9.24187 11.9525 9.314 11.6916 9.4314 11.4569L7.32447 9.02692C7.24017 9.04073 7.15615 9.05177 7.06902 9.05177C6.9389 9.05177 6.81641 9.03051 6.69788 8.99931L4.84215 10.8113C4.87468 10.9259 4.89675 11.0454 4.89675 11.1722C4.89675 11.955 4.25035 12.5859 3.44837 12.5859C2.64583 12.5862 2 11.9553 2 11.1724C2 10.3888 2.64583 9.75866 3.44837 9.75866C3.57794 9.75866 3.70014 9.77937 3.81895 9.81113L5.67468 7.99917C5.64215 7.88375 5.62093 7.76446 5.62093 7.638C5.62093 6.85434 6.26648 6.22422 7.06931 6.22422C7.87129 6.22422 8.51768 6.85434 8.51768 7.638C8.51768 7.80505 8.48232 7.963 8.42857 8.11238L10.5375 10.5451C10.7024 10.4987 10.8727 10.4656 11.0523 10.4656C11.3494 10.4656 11.6257 10.5407 11.8727 10.6644L15.1259 7.638C15.1146 7.56482 15.1033 7.49027 15.1033 7.41378C15.1033 6.63012 15.7488 6 16.5516 6C17.3536 6 18 6.6304 18 7.41378C18 8.19633 17.3536 8.82728 16.5516 8.82728Z" fill="#1D4FD7" />
</svg>

const svgVerticalColumn = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M4.75387 11.5312H2V17.9951H4.75387V11.5312Z" fill="#0DA2E7" />
  <path d="M9.27145 7.98242H6.51758V17.9982H9.27145V7.98242Z" fill="#1D4FD7" />
  <path d="M13.7851 9.87305H11.0312V17.9942H13.7851V9.87305Z" fill="#0DA2E7" />
  <path d="M18 2H15.2461V18H18V2Z" fill="#1D4FD7" />
</svg>

const svgHorizontalColumn = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M18.002 4.75387L18.002 2L2.00195 2L2.00195 4.75387L18.002 4.75387Z" fill="#1D4FD7" />
  <path d="M12.0176 9.27145L12.0176 6.51758L2.00177 6.51758L2.00177 9.27145L12.0176 9.27145Z" fill="#0DA2E7" />
  <path d="M10.002 13.7849L10.002 11.031L1.8808 11.031L1.8808 13.7849L10.002 13.7849Z" fill="#1D4FD7" />
  <path d="M8.00391 18L8.00391 15.2461L1.54004 15.2461L1.54004 18L8.00391 18Z" fill="#0DA2E7" />
</svg>

const svgDoughNut = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.6347 6.02763C12.3636 6.29513 13.705 7.6368 13.9731 9.36638C13.9853 9.44517 14.0253 9.51699 14.0859 9.56886C14.1464 9.62073 14.2235 9.64923 14.3033 9.6492H17.6665C17.7127 9.64913 17.7585 9.63947 17.8008 9.62081C17.8431 9.60216 17.8812 9.57493 17.9124 9.54084C17.9437 9.50675 17.9676 9.46654 17.9825 9.42275C17.9974 9.37897 18.0031 9.33256 17.9992 9.28647C17.6645 5.40066 14.6007 2.33692 10.7143 2.00156C10.6682 1.99745 10.6218 2.00301 10.5779 2.0179C10.5341 2.03278 10.4939 2.05665 10.4599 2.08799C10.4257 2.11928 10.3984 2.15734 10.3798 2.19974C10.3611 2.24215 10.3515 2.28797 10.3516 2.33429V5.6975C10.3516 5.86225 10.4716 6.00252 10.6347 6.02763Z" fill="#0DA2E7" />
  <path d="M17.6658 10.3172H14.3026C14.2228 10.3171 14.1457 10.3457 14.0851 10.3976C14.0246 10.4496 13.9846 10.5215 13.9725 10.6003C13.6685 12.5654 12.0048 13.9916 10.0169 13.9916C7.80648 13.9916 6.00841 12.1935 6.00841 9.98314C6.00841 7.99524 7.4346 6.33158 9.39968 6.02753C9.47853 6.0154 9.55043 5.97544 9.60237 5.91488C9.65431 5.85432 9.68286 5.77718 9.68284 5.6974V2.33419C9.68284 2.24055 9.6437 2.15118 9.57455 2.08789C9.5054 2.02429 9.4134 1.99262 9.32011 2.00146C5.21512 2.35539 2 5.86149 2 9.98311C2 14.4036 5.59611 18 10.0169 18C14.1382 18 17.6443 14.7846 17.9985 10.6799C18.0025 10.6338 17.9968 10.5874 17.9818 10.5436C17.9669 10.4998 17.943 10.4596 17.9118 10.4255C17.8805 10.3914 17.8425 10.3642 17.8002 10.3455C17.7578 10.3269 17.7121 10.3172 17.6658 10.3172Z" fill="#1D4FD7" />
</svg>

const svgTreemap = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 2.89766L10 9.10234C10 9.59811 9.59811 10 9.10234 10L2.89766 10C2.4019 10 2 9.59811 2 9.10234L2 2.89766C2 2.40189 2.40189 2 2.89766 2L9.10234 2C9.5981 2 10 2.40189 10 2.89766Z" fill="#1D4FD7" />
  <path d="M10 11.7854L10 17.2146C10 17.6483 9.59811 18 9.10234 18L2.89766 18C2.4019 18 2 17.6483 2 17.2146L2 11.7855C2 11.3517 2.40189 11 2.89766 11L9.10234 11C9.5981 11 10 11.3517 10 11.7854Z" fill="#0DA2E7" />
  <path d="M18 2.78545L18 5.21455C18 5.64834 17.6483 6 17.2146 6L11.7855 6C11.3517 6 11 5.64834 11 5.21455L11 2.78545C11 2.35166 11.3517 2 11.7854 2L17.2145 2C17.6483 2 18 2.35166 18 2.78545Z" fill="#0DA2E7" />
  <path d="M18 7.78545L18 9.21455C18 9.64834 17.6483 10 17.2146 10L11.7855 10C11.3517 10 11 9.64834 11 9.21455L11 7.78545C11 7.35166 11.3517 7 11.7855 7L17.2145 7C17.6483 7 18 7.35166 18 7.78545Z" fill="#0DA2E7" />
  <path d="M18 15.3366L18 17.6634C18 17.8493 17.8493 18 17.6634 18L15.3366 18C15.1507 18 15 17.8493 15 17.6634L15 15.3366C15 15.1507 15.1507 15 15.3366 15L17.6634 15C17.8493 15 18 15.1507 18 15.3366Z" fill="#7ED4FC" />
  <path d="M14 15.3366L14 17.6634C14 17.8493 13.8493 18 13.6634 18L11.3366 18C11.1507 18 11 17.8493 11 17.6634L11 15.3366C11 15.1507 11.1507 15 11.3366 15L13.6634 15C13.8493 15 14 15.1507 14 15.3366Z" fill="#7ED4FC" />
  <path d="M18 11.3366L18 13.6634C18 13.8493 17.8493 14 17.6634 14L15.3366 14C15.1507 14 15 13.8493 15 13.6634L15 11.3366C15 11.1507 15.1507 11 15.3366 11L17.6634 11C17.8493 11 18 11.1507 18 11.3366Z" fill="#7ED4FC" />
  <path d="M14 11.3366L14 13.6634C14 13.8493 13.8493 14 13.6634 14L11.3366 14C11.1507 14 11 13.8493 11 13.6634L11 11.3366C11 11.1507 11.1507 11 11.3366 11L13.6634 11C13.8493 11 14 11.1507 14 11.3366Z" fill="#7ED4FC" />
</svg>

const svgDropDownIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M6.175 6.91248L10 10.7291L13.825 6.91248L15 8.08748L10 13.0875L5 8.08748L6.175 6.91248Z" fill="#2463EB" />
</svg>