import { useTrans } from "locales/hook";

import Modal from "components/Modal";
import { useStore } from "pages/ReportDashboardV2/context/store";
import { Button } from "@chakra-ui/react";

interface IProps {
    type: string // Delete, Reset
    onClickClose?: Function
    onClickApply?: Function
    addons?: Record<string, any>
}

const HModal: any = Modal;

// * Khi sinh ra 1 popup mới trong màn hình Bảng phân tích, Nội dung Popup đó sẽ được định nghĩa bên trong đây
function ModalReportDashboard(props: IProps) {
    const {
        type,
        onClickClose,
        onClickApply,

    } = props;

    const t = useTrans();

    const { state } = useStore();
    const { addons } = state;

    const handleClose = () => {
        onClickClose && onClickClose();
    }

    const ModalContent = () => {
        let header: any = "";
        let body: any = "";
        let footer: any = () => {
            return ""
        }

        switch (type) {
            case "delete":
                header = `${t("Delete")} ${addons?.dashboardName || ""}`;
                body = () => {
                    return <>
                        <span
                            className="hrv-report-text-color-black-6 hrv-report-mb-8"
                        >
                            {t("Are_you_sure_you_want_to_delete")}{" "}
                            <b>{addons?.dashboardName || ""}</b>?
                        </span>

                        <p className="hrv-report-text-color-black-6 hrv-report-mb-0">
                            {t("This_action_cannot_be_undone")}.
                        </p>
                    </>
                };

                footer = () => {
                    return <div className="hrv-report-w-full hrv-report-d-flex hrv-report-justify-between hrv-report-items-center">
                        <span></span>

                        <div className="hrv-report-d-flex">
                            <Button
                                onClick={() => {
                                    handleClose && handleClose();
                                }}
                                variant="default-default"
                                className="hrv-report-mr-8"
                            >
                                {t("cancelLabel")}
                            </Button>

                            <Button
                                onClick={() => {
                                    onClickApply && onClickApply("delete")
                                }}
                                variant="critical-primary"
                            >
                                {t("Delete")}
                            </Button>
                        </div>
                    </div>
                }
                break;

            case "reset":
                header = "Reset_default";
                body = () => {
                    return <>
                        <p className="hrv-report-text-color-black-6 hrv-report-mb-4">
                            {t("The_analysis_table_will_return_to_the_default_table_provided_by_the_system")}
                        </p>
                        <p className="hrv-report-text-color-black-6 hrv-report-mb-0">
                            {t("Are_you_sure_you_want_to_reset_the_default_table")}
                        </p>
                    </>
                };

                footer = () => {
                    return <div className="hrv-report-w-full hrv-report-d-flex hrv-report-justify-between hrv-report-items-center">
                        <span></span>

                        <div className="hrv-report-d-flex">
                            <Button
                                onClick={() => {
                                    handleClose && handleClose();
                                }}
                                variant="default-default"
                                className="hrv-report-mr-8"
                            >
                                {t("cancelLabel")}
                            </Button>

                            <Button
                                onClick={() => {
                                    onClickApply && onClickApply("reset")
                                }}
                                variant="critical-primary"
                            >
                                {t("Đặt lại")}
                            </Button>
                        </div>
                    </div>
                }

                break;

            default:
                header = "Chưa được định nghĩa";
                body = () => {
                    return "Chưa được định nghĩa"
                };
                footer = () => {
                    return "Chưa được định nghĩa"
                };

                break;
        }

        return {
            header,
            body,
            footer
        }
    }

    const headerModal = () => {
        return <div
            className="
                hrv-report-d-flex 
                hrv-report-items-center 
                hrv-report-justify-between"
        >
            <p
                className="
                hrv-report-mb-0
                hrv-report-text-color-black-6
                hrv-report-fs-16 
                hrv-report-fw-600
                "
            >{t(ModalContent().header)}</p>

            <span
                style={{
                    width: 18,
                }}
                className="
                hrv-report-d-flex 
                hrv-report-items-center 
                hrv-report-cursor-pointer
                hrv-report-justify-between
                "
                onClick={() => {
                    handleClose();
                }}
            >
                {svgClose}
            </span>
        </div>
    }

    return (
        <HModal
            renderHeader={headerModal}
            renderBody={ModalContent().body}
            renderFooter={ModalContent().footer() ? ModalContent().footer : null}
            onClose={handleClose}
            visible={type.length}
            closable={false}
            isCentered
            variant="about"
        />
    )
}

export default ModalReportDashboard;

const svgClose = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.8327 5.34167L14.6577 4.16667L9.99935 8.82501L5.34102 4.16667L4.16602 5.34167L8.82435 10L4.16602 14.6583L5.34102 15.8333L9.99935 11.175L14.6577 15.8333L15.8327 14.6583L11.1743 10L15.8327 5.34167Z" fill="#9CA3B0" />
    </svg>
);